<script>
  import Header from "components/Header.svelte";
  import Main from "components/Pages/Main.svelte";
  import About from "components/Pages/About.svelte";
  import Contacts from "components/Pages/Contacts.svelte";
  import News from "components/Pages/News.svelte";
  import Content from "components/Pages/Content.svelte";
  import Footer from "components/Footer.svelte";
  import MenuContent from "components/Common/MenuContent.svelte";
  import { Router, Route } from "svelte-routing";
  export let url = "";
</script>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .content {
    flex: 1 0 auto;
  }
</style>

<Router>
  <div class="wrapper">
    <Header />
    <div class="content">
      <Route path="about" component={About} />
      <Route path="contact" component={Contacts} />
      <Route path="news" component={News} />
      <Route path="reporting">
        <Content type="reporting" />
      </Route>
      <Route path="counterparties">
        <Content type="counterparties" />
      </Route>
      <Route path="signingDocuments">
        <Content type="signingDocuments" />
      </Route>
      <Route path="eGRILandIGRIP">
        <Content type="eGRILandIGRIP" />
      </Route>
      <Route path="businessRegistration">
        <Content type="businessRegistration" />
      </Route>
      <Route path="/">
        <Main />
      </Route>
    </div>
    <Footer />
  </div>
</Router>
