<script>
  import AdvantagesCard from "./AdvantagesCard.svelte";
  import { image1, image2, image3, image4 } from "static/AdvantagesIcon";
  //mock
  let advantagesData = [
    {
      Icon: image1,
      title: "Все в одном ресурсе",
      text: `Различные сервисы для ведения бизнеса на одном ресурсе`
    },
    {
      title: "Индивидуальный подход и сопровождение партнера",
      Icon: image4,
      text:
        "Анализ бизнес-процессов Заказчика и подбор оптимального варианта интеграции. Бесплатное предоставление API и оказание консультаций по встраиванию"
    },
    {
      title: "Универсальная подпись",
      Icon: image2,
      text: "Работа с одной облачной подписью в разных сервисах"
    },
    {
      Icon: image3,
      title: "Гибкость применения",
      text: "Интеграция с любой информационной системой"
    }
  ];
</script>

<style>
  .wrapper-cards {
    display: flex;
    justify-content: space-around;
    margin: 24px 0;
    flex-wrap: wrap;
  }
  .advantage-header {
    text-align: center;
    font-weight: 300;
    font-size: 28px;
    margin: 20px 0;
  }
</style>

<div class="max-width">
  <div class="advantage-header">Преимущества платформы</div>
  <div class="wrapper-cards">
    {#each advantagesData as { Icon, text, title }, i}
      <AdvantagesCard {Icon} {text} {title} />
    {/each}
  </div>
</div>
