<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import Integration from "components/Main/Integration/Integration.svelte";
  import List from "components/Common/List/List.svelte";
  import ServiceButton from "components/Common/Buttons/ServiceButton.svelte";
  import Popover from "components/Common/Popover.svelte";
  import Divider from "components/Common/Divider.svelte";
  import Tabs from "components/Common/Tabs/Tabs.svelte";
  import TabContainer from "components/Common/Tabs/TabContainer.svelte";
  import { activeService, activeTab } from "../../store";
  import DotsIcon from "static/DotsIcon.svelte";
  import serviceInfo from "../../../servicesInfo/Info.json";
  import FilePlaceholder from "components/Common/FilePlaceholder.svelte";
  import PlaceholderImage from "static/PlaceholderImage";
  export let type;
  if (!Object.keys($activeService).length) {
    activeService.setActiveService(serviceInfo[type]);
  }
  const {
    title,
    icon,
    name,
    description,
    specifications,
    cases
  } = $activeService;
  let activeTabIndex = +location.search.split("tab=")[1] || 0;
  let activeTabWidth, activeTabOffsetLeft;

  onMount(() => {
    activeTabIndex = +location.search.split("tab=")[1] || 0;
    activeTab.setActiveTab(activeTabIndex);
    const reel = document.getElementById("reel");
    const indicator = document.getElementById("indicator");
    const tabs = document.getElementById("tabs");
    let tabChildLength = tabs.children.length - 1;
    let tranformCount = 100 / tabChildLength;
    activeTabWidth = [...tabs.children][activeTabIndex].clientWidth;
    activeTabOffsetLeft = [...tabs.children][activeTabIndex].offsetLeft;
    indicator.style.left = `${activeTabOffsetLeft}px`;
    indicator.style.width = `${activeTabWidth}px`;
    setTimeout(() => {
      reel.children[activeTabIndex].style.height = "auto";
      reel.children[activeTabIndex].style.overflow = "initial";
      reel.children[activeTabIndex].classList.add("active-tab");
      reel.style.transform = `translateX(${activeTabIndex * -tranformCount}%)`;

      activeTabWidth = [...tabs.children][activeTabIndex].clientWidth;
      activeTabOffsetLeft = [...tabs.children][activeTabIndex].offsetLeft;
      indicator.style.left = `${activeTabOffsetLeft}px`;
      indicator.style.width = `${activeTabWidth}px`;
    }, 150);
  });

  const slideToTab = newTabIndex => e => {
    navigate(`/${name}?tab=${newTabIndex}`);
    activeTab.setActiveTab(newTabIndex);
    if (newTabIndex !== activeTabIndex) {
      let oldActiveIndex = activeTabIndex;
      setTimeout(() => {
        reel.children[oldActiveIndex].style.height = "0";
        reel.children[oldActiveIndex].style.overflow = "hidden";
        reel.children[oldActiveIndex].classList.remove("active-tab");
      }, 100);
      activeTabIndex = newTabIndex;
      activeTabWidth = [...tabs.children][activeTabIndex].clientWidth;
      activeTabOffsetLeft = [...tabs.children][activeTabIndex].offsetLeft;
      let tabChildLength = tabs.children.length - 1;
      let tranformCount = 100 / tabChildLength;
      reel.children[activeTabIndex].style.height = "auto";
      reel.children[activeTabIndex].style.overflow = "initial";
      reel.children[activeTabIndex].classList.add("active-tab");
      reel.style.transform = `translateX(${activeTabIndex * -tranformCount}%)`;
      indicator.style.left = `${activeTabOffsetLeft}px`;
      indicator.style.width = `${activeTabWidth}px`;
    }
    if (newTabIndex === 2) {
      setTimeout(() => {
        let a = decodeURIComponent(location.hash)
          .replace(/_/g, " ")
          .replace("#", "")
          .split("—");
        ui.layoutActions.show(a, true);
      }, 300);
    }
  };
  window.slideToTab = slideToTab;
</script>

<style>
  .content-container {
    padding-top: 42px;
  }
  .container-info-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .content-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0056d6;
    border-radius: 50%;
    padding: 15px;
    margin: 0 45px 18px 0;
  }
  :global(.content-icon svg) {
    fill: #fff;
  }
  .content-info-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .content-title {
    color: #2e3e56;
    font-weight: 500;
    font-size: 40px;
  }
  .content-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
</style>

<div class="content-container">
  <div class="max-width container-info-icon">
    <div class="content-icon">
      {@html icon}
    </div>
    <div class="content-info-text">
      <div class="content-title">{title}</div>
      <div class="content-buttons-wrapper">
        <Tabs {slideToTab} {activeTabIndex} />
        <Popover let:active>
          <div slot="button">
            <ServiceButton {active}>
              <div class="button-container">
                <div class="button-icon">
                  <DotsIcon />
                </div>
                <div>Все сервисы</div>
              </div>
            </ServiceButton>
          </div>
          <div slot="content">
            <List />
          </div>
        </Popover>
      </div>
    </div>
  </div>
  <Divider />
  <TabContainer
    {slideToTab}
    {activeTabIndex}
    {cases}
    {description}
    {specifications} />
  <Divider />
  <Integration />
</div>
