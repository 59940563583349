<script>
  import { beforeUpdate, afterUpdate } from "svelte";
  export let selectCase;
  export let slideToTab;
  console.log("selectCase", selectCase);
</script>

<style>
  .wrapper-menu-content {
    display: flex;
    align-content: flex-start;
  }
  .content-markdown {
    width: 75%;
  }
  .content-markdown > ol {
    padding: 0;
  }
  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .menu-item-wrapper {
    position: relative;
    width: 100%;
  }
  .menu-item a {
    transition: all 300ms;
    color: #2e3e56;
  }
  .menu-item:hover a {
    color: #0056d6;
  }
  .menu-item-active a {
    color: #0056d6;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }
  .header-wrapper:hover {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .header-menu {
    font-weight: bold;
    font-size: 12px;
    color: rgba(46, 62, 86, 0.5);
    padding: 16px 24px;
    width: 100%;
    text-decoration: none;
  }
  .small-menu {
    position: sticky;
    top: 0;
    height: 100%;
  }
</style>

<div class="wrapper-menu-content">
  <div class="content-markdown scroll">
    {@html selectCase.content}
  </div>
  <div class="small-menu">
    {#each selectCase.headers as { title, link }, i}
      <div class="menu-list">
        <div id="main-menu" class="menu-item-wrapper">
          <li
            class="header-wrapper level1 {i === 0 && 'menu-item-active'}"
            data-id={i}
            data-href={link}>
            <a class="header-menu" href={link} id="link">
              {title}
            </a>
          </li>
        </div>
      </div>
    {/each}
  </div>
</div>
