<script>
  export let slideToTab;
  export let activeTabIndex;
</script>

<style>
  .tab_wrapper {
    display: flex;
    flex-direction: column;
    background: white;
    overflow: hidden;
  }
  .tab_wrapper header {
    width: 100%;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
  }
  .tab_wrapper header nav {
    width: 100%;
    display: flex;
    position: relative;
  }
  .tab_wrapper header nav .tab {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .tab:hover {
    background-color: rgba(0, 86, 214, 0.06);
    color: #0074c6;
  }
  .tab_wrapper header nav .indicator {
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #0056d6;
    transition: 0.2s ease;
  }
  .activeTab {
    color: #0056d6;
  }
  .tab-text {
    padding: 15px 30px;
    user-select: none;
  }
</style>

<div class="tab_wrapper">
  <header>
    <nav id="tabs">
      <div
        class="{activeTabIndex === 0 && 'activeTab'} tab"
        on:click={slideToTab(0)}>
        <div class="tab-text">Описание</div>
      </div>
      <div
        class="{activeTabIndex === 1 && 'activeTab'} tab"
        on:click={slideToTab(1)}>
        <div class="tab-text">Кейсы</div>
      </div>
      <div
        class="{activeTabIndex === 2 && 'activeTab'} tab"
        on:click={slideToTab(2)}>
        <div class="tab-text">Спецификация API</div>
      </div>
      <div
        class="{activeTabIndex === 3 && 'activeTab'} tab"
        on:click={slideToTab(3)}>
        <div class="tab-text">Документация</div>
      </div>
      <div id="indicator" class="indicator" />
    </nav>
  </header>
</div>
