import { writable } from "svelte/store";

const SelectActiveService = () => {
  const { subscribe, set, update } = writable({});

  return {
    subscribe,
    setActiveService: data => update(services => (services = data))
  };
};

export const activeService = SelectActiveService();

const SelectActiveTab = () => {
  const { subscribe, set, update } = writable(0);

  return {
    subscribe,
    setActiveTab: data => update(tab => (tab = data))
  };
};

export const activeTab = SelectActiveTab();
