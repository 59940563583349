<script>
  import { onMount } from "svelte";
  import MainService from "components/Main/ServiceContent/Service.svelte";
  import Advantages from "components/Main/Advantages/Advantages.svelte";
  import Panthers from "components/Main/Panther/Panthers.svelte";
  import Integration from "components/Main/Integration/Integration.svelte";
  onMount(() => {
    window.scrollTo(0, 0);
  });
</script>

<main>
  <div class="container w-100">
    <MainService />
    <Advantages />
    <Panthers />
    <Integration />
  </div>
</main>
