<script>
  import ServiceCard from "./ServiceCard.svelte";
  import Divider from "components/Common/Divider.svelte";
  import WhiteButton from "components/Common/Buttons/WhiteButton.svelte";

  import ArrowUp from "static/ArrowIcon/ArrowUp.svelte";
  import ArrowDown from "static/ArrowIcon/ArrowDown.svelte";
  // import serviceInfo from "constants/serviceInfo";
  import serviceInfo from "../../../../servicesInfo/Info.json";
  let isShowAllService = false;
  const showAllService = () => {
    isShowAllService = !isShowAllService;
  };
  const width = document.documentElement.clientWidth;
  let serviceCount;
  if (width < 576) serviceCount = 2;
  else if (width < 768) serviceCount = 2;
  else if (width < 1040) serviceCount = 2;
  else if (width < 1399) serviceCount = 3;
  else if (width > 1400) serviceCount = 5;

  let allService = Object.values(serviceInfo);
  let mainService = allService.splice(0, serviceCount);
  console.log("1", allService, "2", mainService);
</script>

<style>
  .wrapper-cards {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    flex-wrap: wrap;
  }
  .container-service {
    padding: 25px 0;
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .service-wrapper {
    display: flex;
    width: 100%;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s, max-height 0.5s;
    flex-wrap: wrap;
  }
  .expanded {
    flex-wrap: wrap;
    max-height: 550px;
    opacity: 1;
    display: flex;
  }
</style>

<div class="container-service max-width">
  <div class="wrapper-cards">
    <div class="service-wrapper expanded">
      {#each mainService as service, i}
        <ServiceCard {service} isShowAllService="true" />
      {/each}
    </div>
    <div class="service-wrapper {isShowAllService && `expanded`}">
      {#each allService as service, i}
        <ServiceCard {service} {isShowAllService} />
      {/each}
    </div>
  </div>
</div>
{#if allService.length}
  <WhiteButton onClick={showAllService}>
    <div class="button-container">
      <div class="button-icon">
        {#if !isShowAllService}
          <ArrowDown />
        {:else}
          <ArrowUp />
        {/if}
      </div>
      <div>Все сервисы</div>
    </div>
  </WhiteButton>
{/if}
