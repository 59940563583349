<script>
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import NewMenuContent from "./NewMenuContent.svelte";
  import FilePlaceholder from "components/Common/FilePlaceholder.svelte";
  import { activeTab } from "../../store";
  import PlaceholderImage from "static/PlaceholderImage";
  export let slideToTab;
  export let index;
  export let menuContent;
  let isContentArray = Array.isArray(menuContent);
  console.log(menuContent);
  const visibilityElement = target => {
    // Все позиции элемента
    if (target) {
      // Все позиции элемента
      var targetPosition = {
          top: window.pageYOffset + target.getBoundingClientRect().top,
          bottom: window.pageYOffset + target.getBoundingClientRect().bottom
        },
        // Получаем позиции окна
        windowPosition = {
          top: window.pageYOffset,
          bottom: window.pageYOffset + document.documentElement.clientHeight
        };

      if (!(targetPosition.bottom - 400 > windowPosition.top)) {
        document.getElementById(`menu-scrollable-tree${index}`).scrollTop -= 50;
      } else if (!(targetPosition.top + 400 < windowPosition.bottom)) {
        document.getElementById(`menu-scrollable-tree${index}`).scrollTop += 50;
      }
    }
  };

  function functionScroll() {
    var section = document.querySelectorAll(
        `.tab_panel.active-tab h1, .tab_panel.active-tab h2`
      ),
      sections = {},
      i = 0;
    Array.prototype.forEach.call(section, function(e) {
      sections[e.id] = e;
    });
    for (i in sections) {
      const toTopValue = sections[i].getBoundingClientRect().top;
      if (toTopValue <= 150) {
        const activeElementsLevel1 = document.querySelector(
          ".tab_panel.active-tab .level1.menu-item-active"
        );
        const activeElementsLevel2 = document.querySelector(
          ".tab_panel.active-tab .level2.menu-item-active"
        );
        const newActiveElementLevel1 = document.querySelector(
          `.tab_panel.active-tab li[data-href="#${i}"].level1`
        );
        const newActiveElementLevel2 = document.querySelector(
          `.tab_panel.active-tab li[data-href="#${i}"].level2`
        );
        const isLevel1 = !!newActiveElementLevel1;
        const isLevel2 = !!newActiveElementLevel2;
        if (isLevel1) {
          activeElementsLevel1.classList.remove("menu-item-active");
          newActiveElementLevel1.classList.add("menu-item-active");
        }
        if (isLevel2) {
          // console.log(activeElementsLevel1.dataset.id)
          let level1 = activeElementsLevel1.dataset.id;
          showNewLevelMenu(+level1);
          activeElementsLevel2.classList.remove("menu-item-active");
          newActiveElementLevel2.classList.add("menu-item-active");
          visibilityElement(newActiveElementLevel2.parentElement);
        }
      }
    }
  }
  let expendedLevelIndex = {
    level1: 0,
    level2: null
  };
  let selectCase;
  let selectCaseIndex;
  if (menuContent && isContentArray) {
    selectCase = menuContent[0];
    selectCaseIndex = 0;
  } else if (menuContent && !isContentArray) {
    selectCase = menuContent.categories[0].cases[0];
    selectCaseIndex = [0, 0];
  }
  const showNewLevelMenu = (level1, level2 = null) => {
    expendedLevelIndex = { level1, level2 };
  };
  onMount(() => {
    if (menuContent && isContentArray) {
      selectCase = menuContent[0];
      selectCaseIndex = 0;
    } else if (menuContent && !isContentArray) {
      selectCase = menuContent.categories[0].cases[0];
      selectCaseIndex = [0, 0];
    }
    window.addEventListener("scroll", functionScroll);
    window.addEventListener("resize", functionScroll);
    (function() {
      scrollTo();
    })();

    function scrollTo() {
      const links = document.querySelectorAll("a[href*='#']");
      links.forEach(each => (each.onclick = scrollAnchors));
    }

    function scrollAnchors(e) {
      const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
      e.preventDefault();
      const targetID =
        decodeURIComponent(this.hash) || decodeURIComponent(e.target.hash);
      const targetAnchor = targetID && document.querySelector(targetID);
      const tabs = (e.target.search && +e.target.search.split("tab=")[1]) || 0;
      tabs && slideToTab(tabs)();
      if (!targetAnchor) return;
      const originalTop = distanceToTop(targetAnchor);
      setTimeout(
        () =>
          window.scrollBy({
            top: originalTop - 100,
            left: 0,
            behavior: "smooth"
          }),
        100
      );
      const checkIfDone = setInterval(function() {
        const atBottom =
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 2;
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
          window.history.pushState("", "", targetID);
          clearInterval(checkIfDone);
        }
      }, 100);
    }
  });
  let isActiveContent = false;
  afterUpdate(() => {
    isActiveContent = $activeTab === index;
  });
  const changeCase = id => {
    console.log("1");
    selectCaseIndex = id;
    selectCase = menuContent[id];
  };
  const changeCaseObject = (parentId, id) => {
    console.log("2");
    selectCaseIndex = [parentId, id];
    selectCase = menuContent.categories[parentId].cases[id];
  };
  onDestroy(() => {
    window.removeEventListener("scroll", functionScroll);
    window.removeEventListener("resize", functionScroll);
  });
</script>

<style>
  .menu-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .menu-data {
    width: 75%;
  }
  .menu-item-wrapper {
    position: relative;
    width: 100%;
  }
  .menu-item {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    border-left: 3px solid transparent;
    padding-left: 15px;
    cursor: pointer;
    color: #2e3e56;
    font-weight: normal;
    font-size: 16px;
    transition: all 300ms;
  }
  .menu-item a {
    transition: all 300ms;
    color: #2e3e56;
  }
  .menu-item:hover {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .menu-item:hover a {
    color: #0056d6;
  }
  .menu-item-active {
    border-color: #0056d6;
  }
  .menu-item-active a {
    color: #0056d6;
  }
  .menu-text {
    width: 100%;
    font-size: 14px;
    padding: 12px 8px;
    transform: scaleX(1);
    flex: 1 1 auto;
    min-width: 0;
    text-decoration: none;
  }

  .menu-data-title {
    font-weight: 500;
    font-size: 36px;
    margin-bottom: 34px;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }
  .header-wrapper:hover {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .header-menu {
    font-weight: bold;
    font-size: 16px;
    color: rgba(46, 62, 86, 0.5);
    padding: 16px 24px;
    width: 100%;
    text-decoration: none;
  }
  .methods {
    margin-top: 50px;
  }
  .menu-title {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 60px;
    width: 25%;
    margin-right: 25px;
    max-height: 93vh;
    overflow: auto;
  }
  .placeholder-wrapper {
    width: 100%;
    height: 350px;
  }

  .mini {
    padding: 0 24px;
  }
  .open {
    display: block;
  }
  .close {
    display: none;
  }
  .menu-content {
    width: 100%;
  }
</style>

<div id="menu-wrapper" class="menu-wrapper">
  {#if isContentArray}
    <div class="menu-title" id={`menu-scrollable-tree${index}`}>
      {#each menuContent as { name, link }, i}
        <div class="menu-list">
          <div id="main-menu" class="menu-item-wrapper">
            <li
              class="header-wrapper {i === selectCaseIndex && 'menu-item-active'}">
              <a href="#" class="header-menu" id="link" on:click={() => changeCase(i)}>
                {name}
              </a>
            </li>
          </div>
        </div>
      {/each}
    </div>
    <div class="menu-data">
      <NewMenuContent {selectCase} {slideToTab} />
    </div>
  {:else if !isContentArray}
    <div class="menu-title" id={`menu-scrollable-tree${index}`}>
      {#each menuContent.categories as { name, link, cases }, i}
        <div class="menu-list">
          <div class="menu-item-wrapper">
            <li
              class="header-wrapper {i === selectCaseIndex[0] && 'menu-item-active'}">
              <a
                href="#"
                class="header-menu"
                on:click={() => {
                  showNewLevelMenu(i);
                  changeCaseObject(i, 0);
                }}>
                {name}
              </a>
            </li>
          </div>
          <div
            class="menu-content {i === expendedLevelIndex.level1 ? 'open' : 'close'}">
            {#each cases as { name, link }, j}
              <div class="menu-item-wrapper">
                <li
                  class="menu-item level2 {j === selectCaseIndex[1] && i === selectCaseIndex[0] && 'menu-item-active'}">
                  <a
                    href="#"
                    class="menu-text scroll"
                    on:click={() => changeCaseObject(i, j)}>
                    {name}
                  </a>
                </li>
              </div>
            {/each}
          </div>
        </div>
      {/each}
    </div>
    <div class="menu-data">
      <NewMenuContent {selectCase} {slideToTab} />
    </div>
  {:else}
    <div class="placeholder-wrapper">
      <FilePlaceholder
        placeholderTitle="В разработке..."
        placeholderText="Документация описания сервиса в разработке."
        {PlaceholderImage} />
    </div>
  {/if}
</div>
