<script>
  import { onMount } from "svelte";
  let ui;
  onMount(() => {
    ui = SwaggerUIBundle({
      url: "./openapi.json",
      dom_id: "#swagger-ui",
      deepLinking: true,
      presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset],
      layout: "StandaloneLayout"
    });
    window.ui = ui;
    setTimeout(() => {
      let element = document.getElementById(decodeURIComponent(location.hash).replace("#", ""));
      console.log(element);
      element && element.scrollIntoView({ behavior: "smooth" });
    }, 500);
  });
</script>

<style>
  #swagger-ui {
    width: 100%;
  }
</style>

<div id="swagger-ui" />
