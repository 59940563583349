<script>
  export let text;
  export let Icon;
  export let title;
</script>

<style>
  .advantages-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 36px 0;
    width: calc((100% - 70px) / 2);
  }
  .advantages-icon {
    width: 92px;
    height: 92px;
    background: white;
    border-radius: 50%;
  }
  .advantages-text {
    font-size: 20px;
    padding: 1rem 2rem 1rem 0;
  }
  .advantages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem 1rem 0;
  }
  .advantages-title {
    font-size: 20px;
    margin-left: 25px;
    font-weight: 600;
  }
  @media (max-width: 766px) {
    .advantages-card-wrapper {
      width: calc((100% - 70px) / 1);
    }
  }
  @media (min-width: 767px) and (max-width: 1139px) {
    .advantages-card-wrapper {
      width: calc((100% - 70px) / 1);
    }
  }
  @media (min-width: 1140px) and (max-width: 1239px) {
    .advantages-card-wrapper {
      width: calc((100% - 70px) / 2);
    }
  }
</style>

<div class="advantages-card-wrapper">
  <div class="advantages-header">
    <div class="advantages-icon">
      <Icon />
    </div>
    <div class="advantages-title">{title}</div>
  </div>
  <div class="advantages-text">
    {@html text}
  </div>
</div>
