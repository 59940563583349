<script>
  export let onClick;
  export let children;
  export let active;
</script>

<style>
  .white-button-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .button-base-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: none;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #fff;
  }
  .button-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 14px 25px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 90px;
  }
  .button-container {
    color: #0056d6;
    fill: #0056d6;
    border: 1px solid #0056d6;
    z-index: 2;
    transition: all 0.7ms;
  }
  .button-container:hover {
    background-color: #0056d6;
    color: #fff;
    fill: #fff;
    text-decoration: none;
  }
  .button-container:active {
    transform: scale(0.97);
  }
  .button-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
  }
  .button-active {
    background-color: #0056d6;
    color: #fff;
    fill: #fff;
    text-decoration: none;
  }
</style>

<div class="white-button-container">
  <button
    class="button-base-root button-root button-container {active && 'button-active'}"
    on:click={onClick}>
    <span class="button-label">
      <slot {children} />
    </span>
  </button>
</div>
