<script>
  import WhiteHeader from "components/Header/WhiteHeader.svelte";
  import CommonHeader from "components/Header/CommonHeader.svelte";
  import newsInfo from "../../news/newsInfo.json";
  let isMainHeader = window.location.pathname;
  let getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
    isMainHeader = location.pathname === "/";
    const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;
    // The object returned here is spread on the anchor element's attributes
    if (!isMainHeader && isActive) {
      return { class: "active" };
    }
    return {};
  };
</script>

<style>
  header {
    position: relative;
    margin-bottom: 50px;
    background: #0056d6 url("/BackgroundHeader.svg") center repeat;
  }
  header.top-header {
    box-shadow: none;
    margin-bottom: 0;
  }
  .d-flex {
    display: flex;
  }
</style>

<header class="d-flex top-header">
  {#if isMainHeader}
    <CommonHeader {getProps} {newsInfo} />
  {:else}
    <WhiteHeader {getProps} />
  {/if}
</header>
