<script>
  import { navigate } from "svelte-routing";
  import { activeService } from "../../../store";
  export let isShowAllService;
  export let service;
  const { title, icon } = service;
  let goToSelectService = () => {
    activeService.setActiveService(service);
    navigate(service.link);
  };
</script>

<style>
  .service-card-wrapper {
    cursor: pointer;
    width: calc((100% - 20px) / 5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .service-card-wrapper:hover .service-icon {
    background-color: rgba(0, 86, 214, 0.06);
  }
  .service-card-wrapper:hover .service-text {
    color: #0056d6;
  }
  .service-icon {
    width: 131px;
    height: 131px;
    background: white;
    margin: 1em;
    border-radius: 50%;
    transition: 0.4s;
  }
  :global(.service-icon svg) {
    fill: #0056d6;
    opacity: 0.8;
  }
  .service-text {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    transition: 0.4s;
  }
  @media (max-width: 767px) {
    .service-card-wrapper {
      width: calc((100% - 20px) / 2);
    }
  }
  @media (min-width: 768px) and (max-width: 1039px) {
    .service-card-wrapper {
      width: calc((100% - 20px) / 2);
    }
  }
  @media (min-width: 1040px) and (max-width: 1239px) {
    .service-card-wrapper {
      width: calc((100% - 20px) / 3);
    }
  }
  @media (min-width: 1240px) and (max-width: 1399px) {
    .service-card-wrapper {
      width: calc((100% - 20px) / 3);
    }
  }
</style>

<div class="service-card-wrapper" on:click={goToSelectService}>
  <div class="service-icon">
    {@html icon}
  </div>
  <div class="service-text">{title}</div>
</div>
