<script>
  import ListItem from "./ListItem.svelte";
  import serviceInfo from "../../../../servicesInfo/Info.json";
  let allService = Object.values(serviceInfo);
</script>

<style>
  .list {
    width: 100%;
    max-width: 360px;
    padding: 16px 0;
  }
</style>

<div class="list">
  {#each allService as service, i}
    <ListItem {service} />
  {/each}
</div>
