<script>
  import { navigate } from "svelte-routing";
  export let date;
  export let title;

  let goToSelectService = () => {
    navigate("/news");
  };
</script>

<style>
  @keyframes border-appearance {
    from {
      transform: translateY(-200px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .container-news-item {
    margin: 35px 0 35px 35px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 1;
    cursor: pointer;
  }
  .wrapper-news-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;
    flex-wrap: wrap;
    color: #fff;
    max-width: 300px;
    padding: 0 25px;
  }
  .news-date {
    opacity: 0.7;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .news-text {
    font-size: 16px;
    font-weight: normal;
  }
  .border {
    flex: 0 0 4px;
    background: #ffffff;
    opacity: 0.24;
    z-index: 1;
  }
  .container-news-item:hover .border {
    opacity: 1;
    transition: 0.5s;
  }
  @media (max-width: 767px) {
    .news-date {
      font-size: 11px;
    }
    .news-text {
      font-size: 13px;
    }
  }
  @media (min-width: 768px) and (max-width: 1139px) {
    .news-date {
      font-size: 12px;
    }
    .news-text {
      font-size: 14px;
    }
  }
  @media (min-width: 1140px) and (max-width: 1239px) {
    .news-date {
      font-size: 13px;
    }
    .news-text {
      font-size: 15px;
    }
  }
  @media (min-width: 1240px) and (max-width: 1399px) {
    .news-date {
      font-size: 14px;
    }
    .news-text {
      font-size: 16px;
    }
  }
</style>

<div class="container-news-item" on:click={goToSelectService}>
  <div class="border" />
  <div class="wrapper-news-item">
    <div class="news-date">{date}</div>
    <div class="news-text">{title}</div>
  </div>
</div>
