<script>
  import { Link } from "svelte-routing";
  import NewItem from "components/Header/NewsItem.svelte";

  import Logo from "static/Logo.svelte";
  import AstralPlatform from "static/AstralPlatform.svelte";
  import Login from "static/Login.svelte";

  export let getProps;
  export let newsInfo;
</script>

<style>
  .container {
    height: 98px;
  }
  .header-container {
    max-width: 1280px;
    margin: auto;
  }
  .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;
  }
  .sub-header > div {
    margin-bottom: 40px;
  }
  .logo {
    margin: 24px 43px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  .header-right {
    display: flex;
    align-items: center;
    padding-top: 11px;
  }
  nav {
    margin-top: 10px;
  }
  nav > :global(a) {
    text-decoration: none;
    color: white;
    margin: 15px 32px 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s;
  }
  nav > :global(a:hover) {
    opacity: 1;
  }
  .wrapper-lk {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .text-lk {
    font-size: 16px;
    color: #fff;
    opacity: 0.5;
    margin-right: 6px;
  }
  .link-lk {
    text-decoration: none;
    color: white;
    font-size: 16px;
    transition: 0.2s;
  }
  .wrapper-lk:hover .link-lk {
    opacity: 0.8;
  }
  .wrapper-lk:hover .icon-lk {
    opacity: 0.8;
  }
  .icon-lk {
    transition: 0.2s;
    width: 32px;
    height: 32px;
    margin-left: 8px;
  }
  :global(.icon-lk svg) {
    fill: white;
  }
  .header-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .header-title-1 {
    color: #ffffff;
    font-weight: 300;
    font-size: 50px;
    display: flex;
    align-items: center;
  }
  .header-title-2 {
    font-weight: normal;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .header-width {
    max-width: 1400px;
    margin: auto;
  }
  @media (max-width: 767px) {
    .logo {
      margin: 24px 10px;
    }
    nav > :global(a) {
      margin: 15px 7px 0;
      font-size: 14px;
    }
    .header-right {
      margin: 0 15px;
    }
    .header-title-1 {
      font-size: 22px;
    }
    .header-title-2 {
      font-size: 12px;
    }
    .text-lk {
      font-size: 14px;
    }
    .link-lk {
      font-size: 14px;
    }
    :global(.logo > a > svg) {
      width: 200px;
      height: 34px;
    }
    nav {
      margin-top: 6px;
    }
  }
  @media (min-width: 768px) and (max-width: 1139px) {
    .logo {
      margin: 24px 15px;
    }
    nav > :global(a) {
      margin: 15px 13px 0;
      font-size: 14px;
    }
    .header-right {
      margin: 0 20px;
    }
    .header-title-1 {
      font-size: 32px;
    }
    .header-title-2 {
      font-size: 14px;
    }
    .text-lk {
      font-size: 14px;
    }
    .link-lk {
      font-size: 14px;
    }
    :global(.logo > a > svg) {
      width: 200px;
      height: 34px;
    }
    nav {
      margin-top: 6px;
    }
  }
  @media (min-width: 1140px) and (max-width: 1239px) {
    .logo {
      margin: 24px 30px;
    }
    nav > :global(a) {
      margin: 15px 27px 0;
      font-size: 16px;
    }
    .header-right {
      margin: 0 60px;
    }
    .header-title-1 {
      font-size: 36px;
    }
    .header-title-2 {
      font-size: 16px;
    }
  }
  @media (min-width: 1240px) and (max-width: 1399px) {
    .logo {
      margin: 24px 43px;
    }
    nav > :global(a) {
      margin: 15px 32px 0;
      font-size: 20px;
    }
    .header-right {
      margin: 0 110px;
    }
  }
</style>

<div class="h-100 w-100">
  <div class="container w-100 header-width">
    <div class="row">
      <div class="header-left">
        <div class="logo">
          <Link to="." {getProps}>
            <Logo />
          </Link>
        </div>
        <nav>
          <Link to="about" {getProps}>О нас</Link>
          <Link to="contact" {getProps}>Контакты</Link>
          <Link to="news" {getProps}>Новости</Link>
        </nav>
      </div>
      <div class="header-right">
        <a class="wrapper-lk" href=".">
          <div class="text-lk">Войти в</div>
          <div class="link-lk">Личный кабинет</div>
          <div class="icon-lk">
            <Login />
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="sub-header w-100 max-width header-container">
    <div class="header-title">
      <div class="header-title-1">Астрал.Платформа —</div>
      <div class="header-title-2">Eдиный портал для работы партнеров</div>
    </div>
    <div>
      {#each newsInfo as { date, title }, i}
        {#if i < 2}
          <NewItem {date} {title} />
        {/if}
      {/each}
    </div>
  </div>
</div>
