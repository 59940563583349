<script>
  import { onMount } from "svelte";
  import FilePlaceholder from "components/Common/FilePlaceholder.svelte";
  import PlaceholderImage from "static/PlaceholderImage";
  import Swagger from "components/Swagger.svelte";
  import NewMenu from "../NewMenu.svelte";
  export let slideToTab;
  export let cases;
  export let specifications;
  export let description;
  export let activeTabIndex;
  console.log(specifications);
  let placeholderTitle = "Контента нет";
  let placeholderText =
    "Пока нет контента, но скоро он обязательно появится :)";
  onMount(() => {
    const tabContent = document.getElementById("reel");
    const childLength = tabContent.children.length;
    tabContent.style.width = `${childLength * 100}%`;
  });
</script>

<style>
  .tab_wrapper {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    background: white;
  }
  .tab_wrapper .tab_reel {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transition: all 0.15s cubic-bezier(0.16, 1, 0.35, 1);
  }
  .tab_wrapper .tab_reel .tab_panel {
    width: 100%;
    display: flex;
    word-break: break-word;
  }
  .tab_wrapper .tab_reel .tab_panel {
    height: 0;
    overflow: hidden;
  }
  .about-info {
    padding: 0 100px;
  }
  :global(.anchor) {
    padding-right: 10px;
    margin-left: -10px;
  }
  :global(.icon-viewer) {
    opacity: 0;
    transition: opacity 450ms;
  }
  :global(h3:hover > a > .icon-viewer, h2:hover > a > .icon-viewer, h1:hover
      > a
      > .icon-viewer) {
    opacity: 1;
  }
  :global(h3:hover > a:hover, h2:hover > a:hover, h1:hover > a:hover) {
    text-decoration: none;
  }

  .placeholder-wrapper {
    width: 100%;
    height: 350px;
  }
</style>

<div class="tab_wrapper max-width">
  <div class="tab_reel" id="reel">
    <div class="tab_panel">
      <div class="about-info">
        {@html description.content}
      </div>
    </div>
    <div id="cases" class="tab_panel">
      <NewMenu menuContent={cases} {slideToTab} index={1} />
    </div>
    <div class="tab_panel">
      <Swagger />
    </div>
    <div hidden id="cases" class="tab_panel">
      <NewMenu menuContent={specifications} index={3} />
    </div>
  </div>
</div>
