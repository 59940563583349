<script>
  import { onMount } from "svelte";

  import NewsPageCard from "components/News/NewsPageCard.svelte";

  onMount(() => {
    window.scrollTo(0, 0);
  });

  const news = [
    {
      date: "29.12.2018",
      title: "Переход на выпуск сертификатов по ГОСТ 2012",
      text: `Уважаемые коллеги!

Уведомляем Вас, что в связи с публикацией на сайте Минкомсвязи Уведомления о необходимости выпуска квалифицированных сертификатов ключей проверки электронной подписи по ГОСТ Р 34.10-2001 в рамках организации перехода на использование схемы электронной подписи по ГОСТ Р 34.10-2012 УЦ "Калуга Астрал" планирует перейти на выпуск сертификатов по ГОСТ 2012. Переход запланирован на 29 декабря 2018 года. После перехода сертификаты по ГОСТ 2001 выпускаться не будут.

Дополнительно уведомляем Вас, что в связи с проведением мероприятий по обеспечению бесперебойной работы УЦ на новом ГОСТ возможна приостановка изготовления сертификатов в период с 1 по 3 января включительно.

Начиная с 1 января 2018 года, будет заблокирована возможность регистрации сертификатов, выданных любым УЦ по ГОСТ 2001 после 1 января.`
    }
  ];
</script>

<div class="w-100 max-width">
  {#each news as { date, title, text }, i}
    <NewsPageCard {date} {title} {text} />
  {/each}
</div>
