<script>
  import newsInfo from "../../../news/newsInfo.json";
  import Divider from "components/Common/Divider.svelte";
  export let date;
  export let title;
  export let text;
</script>

<style>
  .news-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 65px 0;
    width: 100%;
  }
  .news-date {
    font-size: 16px;
    opacity: 0.5;
    margin-bottom: 15px;
  }
  .news-title {
    font-weight: 500;
    font-size: 40px;
    color: #2e3e56;
    margin-bottom: 50px;
  }
  .news-text {
    font-size: 18px;
    color: #2e3e56;
    white-space: pre-wrap;
  }
</style>

<div class="news-container">
  {#each newsInfo as { content, title, date }}
    <div style="font-size: 16px; opacity: 0.5;">{date}</div>
    <h1>{title}</h1>
    <div>
      {@html content}
    </div>
    <Divider />
  {/each}
</div>
