<script>
  export let Icon;
</script>

<style>
  .panther-card-wrapper {
    box-sizing: border-box;
    cursor: pointer;
  }
  .panther-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :global(.panther-icon > svg) {
    height: 80px;
  }
</style>

<div class="panther-card-wrapper">
  <div class="panther-icon">
    <Icon />
  </div>
</div>
