<script>
  import { Router, Link, Route } from "svelte-routing";
  import LogoColored from "../../../static/LogoColored.svelte";
  import AstralPlatform from "../../../static/AstralPlatform.svelte";
  import Login from "static/Login.svelte";
  export let getProps;
</script>

<style>
  header {
    position: relative;
    margin-bottom: 50px;
    background: #0056d6 url("/BackgroundHeader.svg") center repeat;
  }
  .white-header-top {
    background-color: white;
  }
  .container-white {
    height: 98px;
    background-color: white;
    color: black;
  }
  .sub-header > div {
    margin-bottom: 40px;
  }
  .logo {
    margin: 24px 43px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  .header-right {
    display: flex;
    align-items: center;
    padding-top: 11px;
  }
  nav {
    margin-top: 10px;
  }
  nav > :global(a) {
    text-decoration: none;
    color: #2e3e56;
    margin: 15px 32px 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.7;
  }
  nav > :global(a:hover) {
    opacity: 1;
  }
  .wrapper-lk {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .text-lk {
    font-size: 16px;
    color: #2e3e56;
    opacity: 0.5;
    margin-right: 6px;
  }
  .link-lk {
    text-decoration: none;
    color: #2e3e56;
    font-size: 16px;
    cursor: pointer;
  }
  .icon-lk-2 {
    width: 32px;
    height: 32px;
    margin-left: 8px;
  }
  :global(.icon-lk-2 svg) {
    fill: #2e3e56;
  }
  nav > :global(a.active) {
    padding-bottom: 7px;
    border-bottom: 2px solid #0056d6;
    color: #0056d6 !important;
    opacity: 1 !important;
  }
  .header-width {
    max-width: 1400px;
    margin: auto;
  }
  @media (max-width: 767px) {
    .logo {
      margin: 24px 10px;
    }
    nav > :global(a) {
      margin: 15px 7px 0;
      font-size: 14px;
    }
    .header-right {
      margin: 0 15px;
    }
    .header-title-1 {
      font-size: 22px;
    }
    .header-title-2 {
      font-size: 12px;
    }
    .text-lk {
      font-size: 14px;
    }
    .link-lk {
      font-size: 14px;
    }
    :global(.logo > a > svg) {
      width: 200px;
      height: 34px;
    }
    nav {
      margin-top: 6px;
    }
  }
  @media (min-width: 768px) and (max-width: 1139px) {
    .logo {
      margin: 24px 15px;
    }
    nav > :global(a) {
      margin: 15px 13px 0;
      font-size: 14px;
    }
    .header-right {
      margin: 0 20px;
    }
    .header-title-1 {
      font-size: 32px;
    }
    .header-title-2 {
      font-size: 14px;
    }
    .text-lk {
      font-size: 14px;
    }
    .link-lk {
      font-size: 14px;
    }
    :global(.logo > a > svg) {
      width: 200px;
      height: 34px;
    }
    nav {
      margin-top: 6px;
    }
  }
  @media (min-width: 1140px) and (max-width: 1239px) {
    .logo {
      margin: 24px 30px;
    }
    nav > :global(a) {
      margin: 15px 27px 0;
      font-size: 16px;
    }
    .header-right {
      margin: 0 60px;
    }
    .header-title-1 {
      font-size: 36px;
    }
    .header-title-2 {
      font-size: 16px;
    }
  }
  @media (min-width: 1240px) and (max-width: 1399px) {
    .logo {
      margin: 24px 43px;
    }
    nav > :global(a) {
      margin: 15px 32px 0;
      font-size: 20px;
    }
    .header-right {
      margin: 0 110px;
    }
  }
</style>

<div class="white-header-top h-100 w-100">
  <div class="container-white w-100 header-width">
    <div class="row">
      <div class="header-left">
        <div class="logo">
          <Link to="." {getProps}>
            <LogoColored />
          </Link>
        </div>
        <nav>
          <Link to="about" {getProps}>О нас</Link>
          <Link to="contact" {getProps}>Контакты</Link>
          <Link to="news" {getProps}>Новости</Link>
        </nav>
      </div>
      <div class="header-right">
        <a class="wrapper-lk" href=".">
          <div class="text-lk">Войти в</div>
          <div class="link-lk">Личный кабинет</div>
          <div class="icon-lk-2">
            <Login />
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
